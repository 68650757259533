/*
  Inicia a aplicação e tem as rotas definidas
*/
import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'


const Genre = React.lazy(() => import("./pages/Genre"))
const Home = React.lazy(() => import("./pages/NewHome"))
const Subscribe = React.lazy(() => import("./pages/Subscribe"))
const Index_SpeedSize = React.lazy(() => import("./pages/index_speedsize"))
const Title = React.lazy(() => import("./pages/Title"))
const NotFound = React.lazy(() => import("./pages/NotFound"))
const ChannelShows = React.lazy(() => import("./pages/Channel/ChannelShows"))
const HboMax = React.lazy(() => import("./pages/lps/channels/hbo-max/index"))
const Esportes = React.lazy(() => import("./pages/lps/contents/esportes"))
const UFC = React.lazy(() => import("./pages/lps/contents/esportes-ufc"))
const MAX = React.lazy(() => import("./pages/lps/contents/esportes-max/index"))
const Band = React.lazy(() => import("./pages/lps/contents/esportes-band"))
const ParamountEsporte = React.lazy(() => import("./pages/lps/contents/esportes-p+"))
const NBA = React.lazy(() => import("./pages/lps/contents/esportes-nba"))
const CinquentaTons = React.lazy(() => import("./pages/lps/contents/cinquenta-tons"))
const Greys = React.lazy(() => import("./pages/lps/contents/grays-anatomy/index"))
const GreysAfiliados = React.lazy(() => import("./pages/lps/contents/grays-anatomy-afiliados/index"))
const BandSports = React.lazy(() => import("./pages/lps/channels/bandsports"))
const OContoDaAia = React.lazy(() => import("./pages/lps/contents/o-conto-da-aia/index"))
const InimigosIntimos = React.lazy(() => import("./pages/lps/contents/inimigos-intimos"))
const Originais = React.lazy(() => import("./pages/lps/contents/originais"))
const AXN = React.lazy(() => import("./pages/lps/channels/axn"))
const Paramount = React.lazy(() => import("./pages/lps/channels/paramount"))
const Sony = React.lazy(() => import("./pages/lps/channels/sony"))
const History = React.lazy(() => import("./pages/lps/channels/history"))
const PalmeirasdaVirada = React.lazy(() => import("./pages/lps/contents/palmeiras-da-virada"))
const Remarketing = React.lazy(() => import("./pages/lps/contents/remarketing"))
const RemarketingLight = React.lazy(() => import("./pages/lps/contents/remarketing-light"))
const Reality = React.lazy(() => import("./pages/lps/contents/reality"))
const Minu = React.lazy(() => import("./pages/lps/contents/minu"))
const LpColab = React.lazy(() => import("./pages/lps/contents/lp-colab"))
const Rappi = React.lazy(() => import("./pages/lps/contents/rappi"))
const TermosdeUso = React.lazy(() => import("./pages/lps/contents/termos-de-uso"))
const JackBet = React.lazy(() => import("./pages/lps/contents/jackbet"))
const RegulamentoReceba40 = React.lazy(() => import("./pages/lps/contents/regulamento-receba40"))
const Paulistao = React.lazy(() => import("./pages/lps/contents/paulistao"))
const PaulistaoAfiliados = React.lazy(() => import("./pages/lps/contents/paulistao-afiliados"))
const PaulistaoPalmeiras = React.lazy(() => import("./pages/lps/contents/paulistao-palmeiras"))
const PaulistaoCorinthians = React.lazy(() => import("./pages/lps/contents/paulistao-corinthians"))
const PaulistaoSaoPaulo = React.lazy(() => import("./pages/lps/contents/paulistao-saopaulo"))
const PaulistaoSantos = React.lazy(() => import("./pages/lps/contents/paulistao-santos"))
const PaulistaoVoucher = React.lazy(() => import("./pages/lps/contents/paulistao-voucher"))
const UniversalPlus = React.lazy(() => import("./pages/lps/contents/universalplus/index"))
const UniversalPlusVoucher = React.lazy(() => import("./pages/lps/contents/universalplusvoucher/index"))
// const RegulamentoBlackFriday = React.lazy(() => import("./pages/lps/contents/regulamento-black-friday"))

function usePageViews() {
  const location = useLocation();
  const rdxGenres = useSelector((state) => state.genresReducer.genres);

  useEffect(() => {
    // window.scrollTo(0, 0);

    /* Hotjar */
    if (typeof hj !== "undefined" && typeof hj === "function") {
      hj("stateChange", location.pathname);
    }

    /* UOL Analytics */
    if (
      typeof uolAnalytics !== "undefined" &&
      typeof uolAnalytics === "function"
    ) {
      uolAnalytics("pageviews");
    }

    /* Facebook Pixel */
    if (typeof fbq !== "undefined" && typeof fbq === "function") {
      fbq("track", "PageView");
      if (rdxGenres) {
        const genre = rdxGenres.filter(
          (genreItem) => location.pathname.indexOf(genreItem.id) > -1
        );
        if (genre.length > 0) {
          fbq("track", "ViewContent");
        }
      }
    }

    /* Tag Bing Ads */
    window.uetq = window.uetq || [];
    window.uetq.push("event", "page_view", { page_path: location.pathname });
  }, [location, rdxGenres]);
}

function App() {
  usePageViews();

  const queryClient = new QueryClient(
    {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    }
  )

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <React.Suspense fallback={null}>
        <Switch>
          {/* Rotas fixas */}
          <Route exact path="/" component={Home} />
          <Route exact path="/assine" component={Subscribe} />
          <Route exact path="/index_speedsize" component={Index_SpeedSize} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/alugue" component={NotFound} />
          <Route exact path="/termodeuso" component={TermosdeUso} />
          {/* LPs de Canais */}
          <Route exact path="/lp/max" component={HboMax} />
          <Route exact path="/lp/axn" component={AXN} />
          <Route exact path="/lp/paramountplus" component={Paramount} />
          <Route exact path="/lp/bandsports" component={BandSports} />
          <Route exact path="/lp/sony-channel" component={Sony} />
          <Route exact path="/lp/history-channel" component={History} />
          {/* LPs de Conteúdos */}
          <Route exact path="/lp/o-conto-da-aia" component={OContoDaAia} />
          <Route exact path="/lp/uol-play-originais/inimigos-intimos" component={InimigosIntimos} />
          <Route exact path="/lp/uol-play-originais/o-palmeiras-da-virada" component={PalmeirasdaVirada} />
          <Route exact path="/lp/cinquenta-tons" component={CinquentaTons} />
          <Route exact path="/lp/series-medicas" component={Greys} />
          <Route exact path="/lp/series-medicas/afiliados" component={GreysAfiliados} />
          <Route exact path="/lp/esportes" component={Esportes} />
          <Route exact path="/lp/esportes/ufc" component={UFC} />
          <Route exact path="/lp/esportes/nba" component={NBA} />
          <Route exact path="/lp/esportes/max" component={MAX} />
          <Route exact path="/lp/esportes/band-bandsports" component={Band} />
          <Route exact path="/lp/esportes/paramount" component={ParamountEsporte} />
          <Route exact path="/lp/uol-play-originais" component={Originais} />
          <Route exact path="/lp/remarketing-cinema" component={Remarketing} />
          <Route exact path="/lp/remarketing-light" component={RemarketingLight} />
          <Route exact path="/lp/reality-show" component={Reality} />
          <Route exact path="/lp/oferta-voucher" component={Minu} />
          <Route exact path="/lp/oferta-rappi" component={Rappi} />
          <Route exact path="/lp/mr-jack-bet" component={JackBet} />
          <Route exact path="/receba40" component={RegulamentoReceba40} />
          <Route exact path="/lp/colaboradores" component={LpColab} />
          <Route exact path="/lp/esportes/paulistao" component={Paulistao} />
          <Route exact path="/lp/esportes/paulistao/afiliados" component={PaulistaoAfiliados} />
          <Route exact path="/lp/esportes/paulistao/palmeiras" component={PaulistaoPalmeiras} />
          <Route exact path="/lp/esportes/paulistao/corinthians" component={PaulistaoCorinthians} />
          <Route exact path="/lp/esportes/paulistao/sao-paulo" component={PaulistaoSaoPaulo} />
          <Route exact path="/lp/esportes/paulistao/santos" component={PaulistaoSantos} />
          <Route exact path="/lp/esportes/paulistao/parceiros" component={PaulistaoVoucher} />
          <Route exact path="/lp/universalplus" component={UniversalPlus} />
          <Route exact path="/lp/universalplus/resgate" component={UniversalPlusVoucher} />
          {/* <Route exact path="/black-friday" component={RegulamentoBlackFriday} /> */}
          {/* Rotas dinâmicas */}
          <Route exact path="/canal/:name" component={ChannelShows} />
          <Route exact path="/:genre" component={Genre} />
          <Route exact path="/:genre/:id/:titleName" component={Title} />
          {/* Rota padrão para qualquer outra que não esteja nos padrões acima */}
          <Route component={NotFound} />
        </Switch>
      </React.Suspense>
    </QueryClientProvider>
  );
}

export default App;
